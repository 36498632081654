import { useEffect, useState } from 'react';
let pagesCount = 1
const OrganizationInfoIfMat = ({ data }) => {
    const products = data?.products
    const [pageNumber, setPageNumber] = useState(1);
    const [paginationArr, setPaginationArr] = useState([]);
    // --------------------------------------------------------------------------------------------
    const paginateTo = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    // --------------------------------------------------------------------------------------------
    const handleDisable = (el) => {
        return (el === pageNumber)
    }
    // --------------------------------------------------------------------------------------------
    useEffect(() => {
        let paginationArr = []
        pagesCount = Math.ceil(products.length / 10);
        for (let i = 0; i < pagesCount; i++) {
            paginationArr.push(i + 1)
        }
        setPaginationArr(paginationArr)
    }, []);
    return (
        <div>
            <section className='  m-auto pt-3 mb-5' style={{ maxWidth: '816px' }}>
                <div className='text-end'>

                    <span className='w-100 d-flex justify-content-between my-3 '>
                        <span style={{ width: '60%' }} className='regular  ms-2'>{data?.car_num}</span>
                        <span className='bold_ar '>: رقم المركبة</span>
                    </span>

                    <span className='w-100 d-flex justify-content-between my-3 '>
                        <span style={{ width: '60%' }} className='regular  ms-2'> {data?.car_type}</span>
                        <span className='bold_ar '>نوع المركبة</span>
                    </span>

                    <span className='w-100 d-flex justify-content-between my-3 '>
                        <span style={{ width: '60%' }} className='regular  ms-2'>{data?.owner_car}</span>
                        <span className='bold_ar '>: اسم المالك</span>
                    </span>

                    <span className='w-100 d-flex justify-content-between my-3 '>
                        <span style={{ width: '60%' }} className='regular  ms-2'>{data?.car_color}</span>
                        <span className='bold_ar '>: لون المركبة</span>
                    </span>



                    <div className='w-100 d-flex justify-content-center'><span className='type '>المواد</span></div>
                    <div className='mx-3'>
                        <span className='w-100 d-flex justify-content-start my-3 flex-row-reverse text-primary' style={{ borderBottom: '1px solid gray' }}>

                            <span className=' d-flex flex-row-reverse ms-2 product_width' >  <span className=' mx-2'>المواد</span></span>
                            <span className=' ms-2 quantity_status_width' >الحاله</span>
                            <span className=' quantity_status_width'>الكميه</span>
                        </span>
                        {
                            products.slice(((pageNumber * 10) - 10), (pageNumber * 10)).map((el, i) => {
                                return <span key={el.barcode} style={{ borderBottom: '1px solid gray' }} className='px-1  w-100 d-flex justify-content-start my-3 flex-row-reverse'>

                                    <span className=' d-flex flex-row-reverse ms-2 product_width' ><span className='mt-1'>-{i + 1}</span>   <span className=' mx-2'> {el.product_name ||'-'}</span></span>
                                    <span className=' ms-2 quantity_status_width'  >{el.product_status||'-'}</span>
                                    <span className=' quantity_status_width' >{el.quantity||'-'}</span>
                                </span>
                            })
                        }
                        <div className='w-100 d-flex justify-content-center'>
                            {
                                paginationArr?.map((el, i) => {
                                    return <button key={i} disabled={handleDisable(el)} className='btn btn-primary mx-2' onClick={() => {
                                        paginateTo(el)
                                    }}>{el}</button>
                                })

                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className='w-100 d-flex align-items-end flex-column warnning_text_wrapper my-3'>
                <p className='warnning_text_content'>ملحوظــة: لا يسمح دخول و خروج المواد إلا في اوقات الدوام الرسمي</p>
                <p className='warnning_text_content'>تحذيــر: يحق لقسم الأمن والسلامة ايقاف او الغاء التصريح</p>
                <p className='warnning_text_content'>هذا التصريح خاص لحامله فقط ولا يسمح بإرساله لشخص اخر لتجنب المسائلة القانونية</p>
            </div>
        </div>
    );
}

export { OrganizationInfoIfMat };
