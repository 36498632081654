import React from 'react';
import { PersolnalInfoIfCar } from './persolnalInfoIfCar';

const Personalinfo = ({ data,isPerson }) => {
    return (<section className='personal_info_wrapper'>
       {isPerson? <section className='personal_info_sec py-4'>
            <div className='d-flex flex-column text-end personal_table_value w-100 align-items-end' >
                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span className='expire_sec_header' style={{ width: '60%' }}>{data?.date_end}</span>
                    <span className='expire_sec_header ' style={{ textAlign: 'right' }}>ينتهى في</span>
                </span>
                <span className='d-flex flex-column-reverse flex-lg-row text-end personal_table_value w-100 align-items-end' >
                    <span className=' d-flex justify-content-between text-end responsive-width'>
                        <span style={{ width: '60%', overflowWrap: 'breakWord' }} className='row_value'>{data?.partner_ref}</span>
                        <span className='row_head ' style={{ textAlign: 'right' }}>الرقم الوظيفي</span>
                    </span>
                    <span className=' d-flex justify-content-between text-end responsive-width'>
                        <span style={{ width: '60%', overflowWrap: 'breakWord' }} className='row_value'>{data?.partner_name}</span>
                        <span className='row_head ' style={{ textAlign: 'right' }}>الاسم</span>
                    </span>
                </span>
                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.approval_request_no}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>رقم التصريح</span>

                </span>
                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%', color: '#011DD1' }} className='row_value' >{data?.identification_id}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>رقم البطاقة</span>

                </span>
                <span className='d-flex flex-column-reverse flex-lg-row text-end personal_table_value w-100 align-items-end'>
                    <span className=' d-flex justify-content-between text-end responsive-width'>
                        <span style={{ width: '60%', color: '#011DD1' }} className='row_value' >
                            {data?.partner_blood_type || '-'}
                        </span>
                        <span className='row_head ' style={{ textAlign: 'right' }}>فصيله الدم</span>
                    </span>
                    <span className=' d-flex justify-content-between text-end responsive-width'>
                        <span style={{ width: '60%', color: '#011DD1' }} className='row_value' >
                            {data?.national}
                        </span>
                        <span className='row_head ' style={{ textAlign: 'right' }}>الجنسيه</span>
                    </span>
                </span>
            </div>
        </section>:<PersolnalInfoIfCar data={data}/>}


        <section className='d-flex flex-column text-center' style={{ width: '180px', height: '180px', margin: '0 auto' }}>
            {data?.qr_code && <img src={`data:image/jpeg;base64,${data?.qr_code}`} alt="" />}
            <p className='expire_sec_header  text-center text-dark fs-2 w-100 mb-0 mt-2'>تفاصيل التصريح</p>

        </section>
    </section>
    );
}

export { Personalinfo };
