import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Notfound } from './components/404';
import { Permitionpage } from './components/permitionpage';

function App() {
  
  return (
    <BrowserRouter>
    <Routes>
    <Route path={'/'} element={ <Permitionpage/>
} /> 
 <Route path={'*'} element={     <Notfound/>

} />  


</Routes>
</BrowserRouter>
  );
}

export default App;
