import React from 'react';

const Notfound = () => {
    return (
        <div style={{ position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)'}}>
     <h1 style={{color:'#CC9AA6'}}> User Not Found</h1>
<section className="error-container">
  <span className="four"><span className="screen-reader-text">4</span></span>
  <span className="zero"><span className="screen-reader-text">0</span></span>
  <span className="four"><span className="screen-reader-text">4</span></span>
  {/*  */}
</section>

        </div>
    );
}

export  {Notfound};
