import { useSearchParams } from 'react-router-dom';
import { OrganizationInfoIfCar } from './organizationInfoIfCar';

const Organizationinfo = ({ data ,isPerson}) => {
   
    return (
        <div>        
           <section className='  m-auto pt-3 mb-5' style={{ maxWidth: '816px' }}>

           { isPerson?<div className='text-end'>
                <span className='w-100 d-flex justify-content-between my-3 '>
                    < span style={{width:'60%'}} className='regular ms-2' >{(data?.partner_parent_name || '-') + '  ' + (data?.contract || '-')}</span>
                    <span className='bold_ar '>جهة العمل
                    </span>
                    </span>

                    <span className='w-100 d-flex justify-content-between my-3 '>
                        < span style={{width:'60%'}}  className='regular ms-2 d-lg-flex justify-content-between'> 
                        
                        <span className='d-none d-lg-flex justify-content-around text-end w-75 '>
                        <span >
                            {data?.shift_name || '-'}
                        </span>
                        <span  style={{ textAlign: 'right' }}>النوبه</span>
                    </span>

                    {data?.contractsection_name || '-'}
                        </span>
                        <span className='bold_ar '>القسم</span>

                    </span>

                    <span className='w-100 d-lg-none d-flex justify-content-between my-3 '>
                        < span style={{width:'60%'}}  className='regular ms-2'> {data?.shift_name || '-'}</span>
                        <span className='bold_ar text-end'>النوبه</span>

                    </span>


                    <span className='w-100 d-flex justify-content-between my-3 '>
                        < span style={{width:'60%'}}  className='regular ms-2'>{data?.jobs_position || '-'}</span>
                        <span className='bold_ar text-end'>الوظيفة</span>

                    </span>
                    <span className='w-100 d-flex justify-content-between my-3 '>
                        < span style={{width:'60%'}}  className='regular ms-2' >{data?.gate_id || '-'}</span>
                        <span className='bold_ar  ' >البوابة</span>

                    </span>
                    <span className='w-100 d-flex justify-content-between my-3 '>
                    <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.state_cancel || '-'}</span>
                    <span className='bold_ar '>الملاحظات</span>

                    </span>


            </div>:<OrganizationInfoIfCar data={data}/>}

            {/* <div  className='d-flex flex-column  justify-content-between' style={{ height: '400px' }}>
               

            </div> */}
        </section>
            <div className='w-100 d-flex align-items-end flex-column warnning_text_wrapper my-3'>
                <p className='warnning_text_content'>تحذير : - يحق لقسم الامن والسلامه ايقاف او الغاء التصريح</p>
                <p className='warnning_text_content'>هذا التصريح خاص لحامله فقط ولا يسمح بإرساله لشخص أخر لتجنب المسائلة القانونية- </p>
            </div>
        </div>
    );
}

export { Organizationinfo };
