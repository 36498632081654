import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Notfound } from './404';
import { ErrPage } from './errPage';
import { Header } from './header';
import { Organizationinfo } from './organizationinfo';
import { OrganizationInfoIfMat } from './organizationInfoIfMat';
import { Pdfsection } from './pdfsection';
import { PdfMatsection } from './pdfsMatection';
import { Personalinfo } from './personalinfo';
import { PersonalinfoIfMat } from './personalinfoIfMat';

const Permitionpage = () => {
    const [data, setData] = useState({});
    const [isloading, setIsloading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isPerson, setIsPerson] = useState();
    const [type, setType] = useState();
    // http://54.228.10.228:8025/

    const getData = async () => {
        
        setIsloading(true);
        const body = new FormData();
        body.set('url', 
        `http://54.228.13.5:8069/my/approval/${searchParams.get("token")}`
        // "http://54.228.10.228:8025/my/approval/0a3f157b-4c37-4800-b226-aff0f9156ea0"test per
        // "http://54.228.10.228:8025/my/approval/d3052a9f-9eb4-41ea-84d2-8823d461ffde"test car
        // 'http://54.228.10.228:8025/my/approval/827c48dd-c770-4f1b-9f64-ea6b6f638ca7'test mat
        //  `http://54.228.13.5:8069/my/approval/${searchParams.get("token")}`
         );
        body.set('method ', 'get');
        try {
            const res = await axios.post(`https://macro.staging.onesolutionc.com/get_response_data`, body);
            
            setData(res?.data?.result);
            setIsPerson(res?.data?.result?.type === 'PER');
            setType(res?.data?.result?.type);
        }
        catch (err) {
            // console.log(err);
        }
        setIsloading(false)
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            {isloading ? <div className="spin"></div>
                :
                data === "Wrong approval Token" 
                ? <Notfound /> : data?.type === 'PER' || data?.type === 'CAR' ? <>
                    <Header />
                    <Pdfsection data={data} />
                    <Personalinfo isPerson={isPerson}  data={data} />
                    <Organizationinfo isPerson={isPerson} data={data} />
                    </> :
                    // data?.
                    type === 'MAT'?
                    <>
                        <Header />
                    <PdfMatsection data={data} />
                    <PersonalinfoIfMat  data={data} />
                    <OrganizationInfoIfMat data={data}/>
                    </>:
                    <ErrPage />
            }

        </>
    );
}

export { Permitionpage };
