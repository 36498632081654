import React from 'react';

const PersolnalInfoIfCar = ({ data }) => {
    return (
        <section className='personal_info_sec py-4'>

            <div className='d-flex flex-column text-end personal_table_value w-100 align-items-end' >


                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.partner_parent_name||'-'}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>جهة المركبــة</span>
                </span>

                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.car_num||'-'}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>رقم المركبــة</span>
                </span>

                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.car_type||'-'}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>نوع المركبــة</span>
                </span>

                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.car_color||'-'}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>لون المركبــة</span>
                </span>

                <span className=' d-flex justify-content-between text-end responsive-width'>
                    <span style={{ width: '60%' }} className='row_value'>{data?.owner_car||'-'}</span>
                    <span className='row_head ' style={{ textAlign: 'right' }}>اسم المالك</span>
                </span>
            </div>
        </section>
    );
}

export { PersolnalInfoIfCar };
