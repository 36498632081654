import React from 'react';

const OrganizationInfoIfCar = ({data}) => {
    return (
        <div className='text-end'>
    
             <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.driver_name || '-'}</span>
            <span className='bold_ar '>اسم السائــق</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.identification_id || '-'}</span>
            <span className='bold_ar '>الرقم المدني</span>
            </span>
            
            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.national || '-'}</span>
            <span className='bold_ar '>الجنسيــة</span>
            </span>
            
            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.contractsection_name || '-'}</span>
            <span className='bold_ar '>القســـم</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.contract || '-'}</span>
            <span className='bold_ar '>العقــد</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.gate_id || '-'}</span>
            <span className='bold_ar '>بوابات الدخــول</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.exit_gate_id||'-'}</span>
            <span className='bold_ar '>بوابات الخــروج</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.date_start || '-'}</span>
            <span className='bold_ar '>من تاريخ</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.date_end || '-'}</span>
            <span className='bold_ar '>الي تاريخ</span>
            </span>

            <span className='w-100 d-flex justify-content-between my-3 '>
            <span  style={{width:'60%'}}  className='regular  ms-2'>{data?.state_cancel||'-'}</span>
            <span className='bold_ar '>الملاحظات</span>
            </span>

    </div>
    );
}

export  {OrganizationInfoIfCar};
