import React from 'react';

const PersonalinfoIfMat = ({ data }) => {
    return (
        <section className='personal_info_wrapper'>
    <section className='personal_info_sec py-4'>

<div className='d-flex flex-column text-end personal_table_value w-100 align-items-end' >


    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.partner_parent_name}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: اسم الشركة</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.gate_id}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: بوابات الدخول</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.exit_gate_id}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: بوابات الخروج</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.approval_request_no}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: رقم التصريح</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.driver_name}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: اسم السائق</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.national}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: الجنسية</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.identification_id}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: الرقم المدني</span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.contractsection_name}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: القسم </span>
    </span>

    <span className=' d-flex justify-content-between text-end responsive-width'>
        <span style={{ width: '60%' }} className='row_value'>{data?.contract}</span>
        <span className='row_head ' style={{ textAlign: 'right' }}>: العقد </span>
    </span>

</div>
</section>
 <section className='d-flex flex-column text-center' style={{ width: '180px', height: '180px', margin: '0 auto' }}>
 {data?.qr_code && <img src={`data:image/jpeg;base64,${data?.qr_code}`} alt="" />}
</section>
</section>
    );
}

export { PersonalinfoIfMat };
