import { useState } from 'react';
import avatar from '../images/sebia_user.webp'

const PdfMatsection = ({ data }) => {
  //   const print = () => {
  //     setIsloading(true)
  //     fetch(data?.download_pdf).then(response => {
  //         response.blob().then(blob => {
  //             const fileURL = window.URL.createObjectURL(blob);
  //             let alink = document.createElement('a');
  //             alink.href = fileURL;
  //             alink.download = 'SamplePDF.pdf';
  //             alink.click();
  //         })
  //     })
  //     setIsloading(false)
  // }
  return (
    <section className='row pdf_sec_wrapper   p-0 m-0  '>
      {<div className='col-12 col-xl-4  button_pdf_div d-flex justify-content-center'>
        {data?.download_pdf2 && <a href={data?.download_pdf2} target='_blanck'>   <button  className=' pdf_button'>
          Download PDF 2
        </button>
        </a>}
      </div>}
      <div className='col-12 col-xl-4  button_pdf_div d-flex justify-content-center'>
        <a href={data?.download_pdf} target='_blanck'>   <button  className=' pdf_button'>
          Download PDF {data.download_pdf2 && 1}
        </button>
        </a>
      </div>
      <div className='col-12 col-xl-4 pdf_img_wrapper'>
        {<img className='pdf_img mx-auto' src={data?.image_1920 ? `data:image/jpeg;base64,${data?.image_1920}` : avatar} alt='user img' />}
        <p className='valid_from d-flex text-center  justify-content-center '>
          <span className='valid_from_mat_date text-danger'> {data?.date_end}  </span>
          <span className='valid_from_mat_ar_text mx-2'> إلي تاريخ</span>
          <span className='valid_from_mat_date text-primary'> {data?.date_start} </span>
          <span className='valid_from_mat_ar_text mx-2  '>مسـمـوح من تــاريـخ</span>


        </p>

      </div>
      <div className='col-22 col-xl-4   '></div>
    </section>
  );
}

export { PdfMatsection };
